<template>
  <div class="error">
    <div>
      We can't find this page
    </div>
    <div>Go to <router-link to="/">Home</router-link></div>
  </div>
</template>

<script>
  export default {
    name: 'MainError'
  }
</script>